.head{
    display: flex;
    justify-content: space-between;
    width:100%;
    margin: 2rem 0px
}
.head>img{
    width: 14rem;
    padding: 2rem;
    padding-top: 1.4rem;
}
.nav{
    display: flex;
    padding: 2rem;
    padding-top: 0px;

}
.nav li {
    margin: 1rem;
    color: white;
}
.nav li:hover {
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width:768px){
    
    .nav{
        flex-direction: column;
        background-color: var(--appColor);
        z-index: 20;
        position: absolute;
        top: 15%;
        right: 5%;
        padding: 0px;
    }
   
    .head>:nth-child(2)>img{
        padding-top: 2rem;
        width: 2rem;
        height: 3rem;
        margin-right: 1rem;
    }
    
}

.plans{
    background-color: var(--darkGrey);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    left: 0px;
    
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0px;
}

.headline{
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.headline span{
    font-size: 4rem;
    font-weight: 900;
}
.plan-cards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin: 4rem 0px;
}

.plan-card{
    background-color: var(--caloryCard);
    padding: 1.5rem;
    height: 25rem;
    display: flex;
    flex-direction: column ;
    gap: 2rem;
    color: white;
    z-index: 3;

}

.card-icon{
    width: 2rem;
}
.card-icon>svg{
    fill: var(--orange);
    
}
.plan-card img{
    width: 1.5rem;
    
}
.card-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.plan-card>div:nth-child(3){
    display: flex;
    justify-content: center;
}
.plan-btn{
    padding: 1rem;
    border: transparent;
    font-size: 16px;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 200ms linear;
}
.plan-btn:hover{
    border: 2px solid var(--orange);
}

.orange-color{
    color: var(--orange);
}

.plan-card:nth-child(2){
    background: linear-gradient(rgb(235, 105, 18), rgb(201, 39, 39));
    transform: scale(1.1);
    
}

.plan-card:nth-child(2)>.card-icon>svg{
    fill: white;

}

.plan-card:nth-child(2) h2{
    color: white;
}
.plan-card:nth-child(2) .plan-btn{
    color: var(--orange);
}

@media screen and (max-width:768px) {
    .headline{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .headline>span{
        font-size: x-large;
    }
    .plan-cards{
        flex-direction: column;
    }
    
}

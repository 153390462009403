.Testimonials{
    display: flex;
    background-color: var(--darkGrey);
    padding: 0px 2rem;
    padding-bottom: 2rem;
}

.t-left{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    
}

.t-right{
    flex: 1 1;
    position: relative;
}

.t-right img{
    width: 13rem;
    position: absolute;
    top:2rem;
    right: 10rem;
    height: 16rem;

}

.t-right>:nth-child(1){
    position: absolute;
    top: 1rem;
    right: 11rem;
    border: 5px solid orange;
    background-color: transparent;
    width: 13rem;
    height: 15rem;
}

.t-right>:nth-child(2){
    position: absolute;
    background: linear-gradient( #fa5042, #ffa739);
    width: 13rem;
    height: 15rem;
    top: 4rem;
    right: 9rem ;
}
.review{
    min-height: 3rem;
}


.arrows{
    position: absolute;
    right: 20rem;
    /* bottom: 0px; */
    top: 17rem;
    
    
}
.arrows img{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.left-arrow{
    position: absolute;
    right: 23rem;
    top: 17rem;
}

.left-arrow img{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .Testimonials{
        flex-direction: column-reverse;
    }

    .t-left>:nth-child(3){
        margin-bottom: 300px;

    }
    .t-left>:last-child{
        margin-bottom: 4rem;
    }
    .t-right>:nth-child(1){
        left: 6%;
        top: 22rem;
    }

    .t-right>:nth-child(2){
        left: 20%;
        top: 24rem;
    }
    .p-image>img{
       
        left: 13%;
        top: 22rem;
    }

    .arrows{
        top: 55rem;
        right: -8rem;
       
    }

    .left-arrow{
        top: 55rem;
        right: 1rem;
    }

    .review{
        height: 100px;
    }
    

    
    
}
.programs{
    display: flex;
    flex-direction: column ;
    padding: 2rem 0px;
    background-color: var(--darkGrey);
}
.programs>div{
    display: flex;
    margin: 2rem 2rem;
    justify-content: space-between;
}
.programs>div>span{
    font-size: 4rem;
    font-style: italic;
    font-weight: 900;
    
}
.special{
    color: white;
}
.programs-ul{
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 768px){
    .programs>div:nth-child(1){
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        
    }
    .programs>div>span{
        font-size: x-large;
    }

    .programs-ul{
        flex-direction: column;
    }
}
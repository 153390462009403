.reasons{
    display: flex;
    background-color: var(--darkGrey);
    padding: 2rem ;
    


}

.left-r{
    flex: 1 1;
}

.right-r{
    flex: 1 1;
}

@media screen and (max-width: 768px){
    .reasons{
        flex-direction: column;
    }
}
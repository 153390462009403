.footer-container{
    background-color: var(--darkGrey);
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    z-index: 2;
}

.social-links{
    display: flex;
    gap: 4rem;
    
}
.social-links img{
    width: 3.5rem;
    cursor: pointer;
}

.logo-footer img{
    width: 10.2rem;
}

.footer-container>p{
    margin-top: -50px;
    padding-top: 0px;
    margin-bottom: 1rem;
    font-weight: 700;
    text-align: center;
    color: white;
}

.footer-blur-1{
    width: 32rem;
    height: 23rem;
    bottom: 0px;
    right: 10%;
  
}

.footer-blur-2{
    width: 32rem;
    height: 23rem;
    bottom: 0px;
    left: 10%;
   

}

@media screen and (max-width:768px) {
    .social-links img{
        width: 2rem;
        cursor: pointer;
    }  
    .logo-footer img{
        width: 7.2rem;
    }
    .footer-container>p{
        margin-top: -50px;
        padding-top: 0px;
        margin-bottom: 1rem;
        font-weight: 400;
        text-align: center;
        color: white;
        font-size: small;
    }

    .footer-blur-1{
        width: 22rem;
        height: 13rem;
        bottom: 0px;
        right: 10%;
      
    }
    
    .footer-blur-2{
        width: 22rem;
        height: 13rem;
        bottom: 0px;
        left: 10%;
       
    
    }
}
.container{
    display: flex;
    justify-content: space-between;
    position: relative;

}
.hero-blur{
    width: 13rem;
    height: 32rem;
    left: 0px;
    top: 4rem;
    z-index: 2;
    
}

.left-hero{
    flex: 3;
    background-color: var(--darkGrey);
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    position: relative;
    
    }

.right-hero{
    flex: 1;
    background-color: var(--orange);
    position: relative;
}
.main{
    padding-left: 2rem;
    z-index: 3;
}
.tagline{
    color: white;
    font-size: 1rem;
    margin-top: 1rem;
}

.main-text{
    font-weight: 700;
}

.stats{
    margin-top: 2rem;
    display: flex;
    gap: 7rem;
}
.stats div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.statsCount{
    font-size: 2rem;
    color: white;
    font-weight: bold;
}
.statsDescription{
    color: var(--gray);
}
.btn-group{
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    padding-left: 0px; 

}

.btn{
    font-size: 16px;
    padding: 1rem;
    margin-right: 1rem;
    
    border: transparent;
    color: white;
    border: 2px solid var(--orange);
    cursor: pointer;
    border-radius: 5px;
    transition: all 300ms linear;
}

#btn-1{
    background-color: var(--orange);
    
 
}
#btn-1:hover{
    background-color: var(--darkGrey);
}

#btn-2{
    background-color: transparent;
    
}

#btn-2:hover{
    background-color: var(--orange);
}
.joinNowDiv{
    text-align: center;
}
.joinNow{
    margin: 2.5rem;
    padding: 0.7rem;
    cursor: pointer;
    border-radius: 5px;
    border: transparent;
    transition: all 200ms linear;
}
.joinNow:hover{
    color: white;
    background-color: var(--darkGrey);
}
.heartRate{
    background-color: var(--darkGrey);
    width: 8rem;
    text-align: center;
    position: absolute;
    left: 30%;
    padding: 1rem;
    border-radius: 1rem;
}
.heartRate img{
    width: 2rem;
}
.heartRate div{
    color: white;
}

.hero img:nth-of-type(1){
    width: 22rem;
    position: absolute;
    left: -40%;
    top: 30%;
    z-index: 2;
}

.hero img:nth-of-type(2){
    position: absolute;
    left: -65%;
    top: 24%;
    z-index: 1;
    width: 14rem;

}

@media  screen and (max-width: 768px) {
    .container{
        flex-direction: column;
    }
    .main{
        text-align: center;
    }
    .main-text{
        font-size: xx-large;
        text-align: center;

    }
    .stroke-text{
        font-size: xx-large;
    }
    .tagline{
        font-size: small;
        letter-spacing: 1;
        text-align: center;
    }

    .stats>div>:nth-child(1){
        font-size: large;
    }
    .stats>div>:nth-child(2){
        font-size: small;
    }
    .stats{
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .btn-group{
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
        /* text-align: center; */
        /* padding: 2rem; */
        /* padding-left: 0px; */
        padding: 0px;
    }

    .btn{
        font-size: 16px;
        padding: 0.5rem;
        margin-right: 1rem;
    }

    .heartRate{
        position: relative;
        left: 0px;
        top: 0px;
        background-color: var(--orange);
    }
    .right-hero{
        background-color: var(--darkGrey);
    }

    .hero{
        position: relative;
    }
    .hero>img:nth-child(1){
        position: relative;
        width: 15rem;
        top: 0px;
        left: 20%;
    }
    .hero>img:nth-child(2){
        display: none;
    }
}

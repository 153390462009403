.gallery{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    /* the following property will make the height of evry 
    row equal */
    grid-auto-rows: 1fr;

    gap: 1rem;
}

.gallery img{
    object-fit: cover;
}

.gallery>img:nth-child(1){
    width: 14rem;
    height: 28rem;
    grid-row: 1/3;
}

.gallery>img:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}

.gallery>img:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;

}

.gallery>img:nth-child(4){
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
}

@media screen and (max-width: 768px){
    .gallery{
        grid-auto-rows: auto;
        overflow: hidden;
        margin-bottom: 4rem;
    }

    .gallery>img:nth-child(1){
        width: 7rem;
        height: 14rem;
        grid-row: 1/3;
    }
    
    .gallery>img:nth-child(2){
        width: 7rem;
        height: 8rem;
        grid-column: 2/4;
    }
    
    .gallery>img:nth-child(3){
        width: 4rem;
        height: 4rem;
        grid-column: 2/3;
        grid-row: 2;
    
    }
    
    .gallery>img:nth-child(4){
        width: 3rem;
        grid-column: 3/4;
        grid-row: 2;
        height: 4rem;
    }


}
